import React from 'react';
import { graphql } from 'gatsby';

import Parents from '../../assets/svg/Parents.svg';

import s from './Category.module.scss';
import { useIntl } from 'gatsby-plugin-intl';
import { AlgoliaMini } from '../../app/shared/components/algolia-mini/AlgoliaMini.component';
import { Breadcrumbs } from '../../app/shared/components/breadcrumbs/Breadcrumbs.component';
import { PostGrid } from '../../app/knowledge-base/post-grid/PostGrid.component';
import { TemplateHelper } from '@helpers/templates.helper';
import { Banner } from '../../app/knowledge-base/banner/Banner.component';
import { Teasers } from '../../app/knowledge-base/teasers/Teasers.component';
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';
import { useLocalizedDynamicRoute } from '../../hooks/use-localized-dynamic-route';
import { useSiteMetadata } from '../../hooks/use-static-metadata';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { ThumbnailsHelper } from '@helpers/thumbnails.helper';
import { coversDictionary } from '@helpers/covers.helper';

const CategoryParents = (props) => {

  const {
    bannerB,
    lastArticles,
    lastVideos,
    lastGuides,
    allArticles,
    allVideos,
    allGuides,
    featuredParents,
    goodToKnow,
    tutorials,
  } = props.data;
  
  const pinned = featuredParents.nodes[0].featuredParentsArticles.articlesForParents;

  const lastPosts = [
    ...lastArticles.edges,
    ...lastVideos.edges,
    ...lastGuides.edges,
  ];

  const allPosts = [
    ...allArticles.edges,
    ...allVideos.edges,
    ...allGuides.edges,
  ];

  const flatLastPosts = lastPosts.map(TemplateHelper.getNode).flat(Infinity);
  const sortedLastPosts = flatLastPosts.sort(TemplateHelper.sortByDatePosts);

  const flatAllPosts = allPosts.map(TemplateHelper.getNode).flat(Infinity);
  const sortedAllPosts = flatAllPosts.sort(TemplateHelper.sortByDatePosts);

  const dontMissPosts = [...pinned, ...sortedLastPosts.slice(0, 4 - pinned.length)];
  const dontMissPostsIds = dontMissPosts.map(element => element.id);
  const categoryPostsUnique = sortedAllPosts.filter(element => !dontMissPostsIds.includes(element.id));

  const categoryPosts = categoryPostsUnique.slice(0, 6);
  
  const intl = useIntl();

  const link = useLocalizedDynamicRoute();
  const { siteUrl } = useSiteMetadata();

  return (
    <>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.CATEGORY_PARENTS)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.CATEGORY_PARENTS)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.CATEGORY_PARENTS)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.CATEGORY_PARENTS)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.CATEGORY_PARENTS)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.CATEGORY_PARENTS)}/`,
          },
        ]}
        canonical={siteUrl+link(PageType.CATEGORY_PARENTS)+'/'}
        title={intl.formatMessage({ id: 'common__knowledgeBaseTitle' })}
        description={intl.formatMessage({ id: 'common__knowledgeBaseDescription' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: ThumbnailsHelper[intl.locale],
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'common__knowledgeBaseTitle' }),
          },
          {
            property: 'og:image',
            content: coversDictionary[intl.locale],
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'common__knowledgeBaseDescription' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'common__knowledgeBaseTitle' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'common__knowledgeBaseDescription' }),
          },
        ]}
      />
      <div className={s.category}>
        <AlgoliaMini isTopBarVisible={true}/>
        <div className={s.category__wrapper}>
          <Breadcrumbs crumbs={[
            { name: intl.formatMessage({ id: 'common__knowledgeBase' }), path: '../../' },
            { name: intl.formatMessage({id: 'slugs__forParents'}), path: './' },
          ]}/>

          <div className={s.category__graphic}>
            <div className={s.category__icon}>
              <Parents className={s.category__iconSvg}/>
            </div>
            <p className={s.category__title}>{intl.formatMessage({ id: 'category__parentsCategory' })}</p>
          </div>
          <div className={s.category__description}>
            <p className={s.category__text}
               dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'category__parentsDescription' }) }}/>
          </div>
        </div>

        <PostGrid posts={dontMissPosts}
                  header={intl.formatMessage({ id: 'common__dontMiss' })}/>
        <Banner banner={bannerB}/>
        <PostGrid posts={categoryPosts}/>
        <Teasers
          smallTeasersSet={goodToKnow}
          bigTeasersSet={tutorials}
        />
      </div>
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export const queryParents = graphql`
  query GetCategoryParents($locale: String) {
    lastVideos: allWpVideo(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
    ){
      edges {
        node {
          ...VideoInfo
          ...VideoCategories
          ...VideoImage
        }
      }
    }
    lastArticles: allWpArticle(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
    ) {
      edges {
        node {
          ...ArticleInfo
          ...ArticleCategories
          ...ArticleImage
        }
      }
    }
    lastGuides: allWpGuide(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
    ) {
      edges {
        node {
          ...GuideInfo
          ...GuideCategories
          ...GuideImage
        }
      }
    }
    featuredParents: allWpFeaturedParentsArticle(
      filter: {
        locale: {locale: {eq: $locale}}
      }
    ) {
      nodes {
        featuredParentsArticles {
          articlesForParents {
            ... on WpArticle {
              ...ArticleInfo
              ...ArticleCategories
              ...ArticleImage
            }
            ... on WpGuide {
              ...GuideInfo
              ...GuideCategories
              ...GuideImage
            }
            ... on WpVideo {
              ...VideoInfo
              ...VideoCategories
              ...VideoImage
            }
          }
        }
      }
    }
    allVideos: allWpVideo(
      sort: {fields: date, order: DESC}
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
    ) {
      edges {
        node {
          ...VideoInfo
          ...VideoCategories
          ...VideoImage
        }
      }
    }
    allArticles: allWpArticle(
      sort: {fields: date, order: DESC}
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
    ) {
      edges {
        node {
          ...ArticleInfo
          ...ArticleCategories
          ...ArticleImage
        }
      }
    }
    allGuides: allWpGuide(
      sort: {fields: date, order: DESC}
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
    ) {
      edges {
        node {
          ...GuideInfo
          ...GuideCategories
          ...GuideImage
        }
      }
    }
    bannerB:   allWpBanner(filter: {slug: {eq: "banner-b"}, locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          bannerProperties {
            bannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerMobileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerUrl
          }
        }
      }
    }
    goodToKnow: allWpGuide(
      limit: 6
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          ...GuideInfo
        }
      }
    }
    tutorials: allWpVideo(
      limit: 3
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {in: ["rodzice", "rodice", "parents-fr", "parents", "eltern", "padres"]}}}}
      }
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          ...VideoInfo
          ...VideoImage
          videoContent {
            videoYoutube
          }
        }
      }
    }
  }
`;

export default CategoryParents;
